export default {
    title: {
        new: "Add New Address",
        complete: "Complete Address",
        edit: "Edit Address"
    },
    incomplete: "Please fill required address fields",
    model: {
        country: {
            label: "Country"
        }
    },
    field: {
        default: {
            name: {
                label: {Name: "Name"},
                placeholder: {Name: "Name"}
            },
            personalEmail: {
                label: {"Personal Email": "Personal Email"},
                placeholder: {"Personal Email": "Personal Email"}
            },
            managerEmail: {
                label: {"Manager Email": "Manager's Email"},
                placeholder: {"Manager Email": "Manager's Email"}
            },
            managerName: {
                label: {"Manager Name": "Manager's Name"},
                placeholder: {"Manager Name": "Manager's Name"}
            },
            postalCode: {
                label: {Zipcode: "Zipcode"},
                placeholder: {Zipcode: "Zipcode"}
            },
            phoneNumber: {
                label: {"Phone Number": "Phone Number"},
                placeholder: {"Phone Number": "Phone Number"}
            },
            firstStreet: {
                label: {"Street": "Street"},
                placeholder: {"Street": "Street"}
            },
            secondStreet: {
                label: {"Street": "Street"},
                placeholder: {"Street": "Street"}
            },
            city: {
                label: {
                    "City": "City",
                    "Town/City": "City"
                },
                placeholder: {
                    "City": "City",
                    "Town/City": "City"
                }
            },
            identifier: {
                label: {
                    "identifier": "Identifier",
                    "Identifier": "Identifier"
                },
                placeholder: {
                    "identifier": "Identifier",
                    "Identifier": "Identifier"
                }
            },
            state: {
                label: {"State": "State"},
                options: {
                    "Alabama": "Alabama",
                    "Alaska": "Alaska",
                    "Arizona": "Arizona",
                    "Arkansas": "Arkansas",
                    "California": "California",
                    "Colorado": "Colorado",
                    "Connecticut": "Connecticut",
                    "Delaware": "Delaware",
                    "District Of Columbia": "District Of Columbia",
                    "Florida": "Florida",
                    "Georgia": "Georgia",
                    "Hawaii": "Hawaii",
                    "Idaho": "Idaho",
                    "Illinois": "Illinois",
                    "Indiana": "Indiana",
                    "Iowa": "Iowa",
                    "Kansas": "Kansas",
                    "Kentucky": "Kentucky",
                    "Louisiana": "Louisiana",
                    "Maine": "Maine",
                    "Maryland": "Maryland",
                    "Massachusetts": "Massachusetts",
                    "Michigan": "Michigan",
                    "Minnesota": "Minnesota",
                    "Mississippi": "Mississippi",
                    "Missouri": "Missouri",
                    "Montana": "Montana",
                    "Nebraska": "Nebraska",
                    "Nevada": "Nevada",
                    "New Hampshire": "New Hampshire",
                    "New Jersey": "New Jersey",
                    "New Mexico": "New Mexico",
                    "New York": "New York",
                    "North Carolina": "North Carolina",
                    "North Dakota": "North Dakota",
                    "Ohio": "Ohio",
                    "Oklahoma": "Oklahoma",
                    "Oregon": "Oregon",
                    "Pennsylvania": "Pennsylvania",
                    "Rhode Island": "Rhode Island",
                    "South Carolina": "South Carolina",
                    "South Dakota": "South Dakota",
                    "Tennessee": "Tennessee",
                    "Texas": "Texas",
                    "Utah": "Utah",
                    "Vermont": "Vermont",
                    "Virginia": "Virginia",
                    "Washington": "Washington",
                    "West Virginia": "West Virginia",
                    "Wisconsin": "Wisconsin",
                    "Wyoming": "Wyoming"
                }
            },
            deliveryInstructions: {
                label: {
                    "Delivery Instructions": "Delivery Instructions"
                },
                placeholder: {
                    "Extra address information, delivery instructions, etc": "Extra address information, delivery instructions, etc."
                }
            },
            servicePoint: {
                label: {"Service Point": "Service Point"},
                placeholder: {"Service Point": "Service Point"},
                options: {
                    "Front Door": "Front Door",
                    "Back Door": "Back Door"
                }
            },
            parkingRestrictions: {
                label: {"Restrictions for Parking": "Restrictions for Parking"},
                placeholder: {"Restrictions for Parking": "Restrictions for Parking"},
                options: {
                    "Yes": "Yes",
                    "No": "No"
                }
            },
            lockOrGateCodeRequired: {
                label: {"Lock or Gate Code Required": "Lock or Gate Code Required"},
                placeholder: {"Lock or Gate Code Required": "Lock or Gate Code Required"},
                options: {
                    "Yes": "Yes",
                    "No": "No"
                }
            },
            animals: {
                label: {"Animals at Property": "Animals at Property"},
                placeholder: {"Animals at Property": "Animals at Property"},
                options: {
                    "Yes": "Yes",
                    "No": "No"
                }
            },
            stairs: {
                label: {"Use Stairs": "Use Stairs"},
                placeholder: {"Use Stairs": "Use Stairs"},
                options: {
                    "Yes": "Yes",
                    "No": "No"
                }
            }
        },
        ARG: {
            identifier: {
                placeholder: {"Tax ID (CDI, CUIT, CUIL, etc)": "cdi, cuit, cuil, dni"}
            },
            firstStreet: {
                placeholder: {"Number/Street/Apartment": "calle, domicilio, apartamento, piso, barrio"}
            },
            city: {
                placeholder: {"Town/City/Locality": "pueblo, ciudad, localidad"}
            },
            postalCode: {
                placeholder: {"CPA": "codigo postal argentina"}
            },
            deliveryInstructions: {
                placeholder: {"Extra address information, delivery instructions, etc": "direccion adicional extra o direccion exacta, etc"}
            }
        },
        BRA: {
            name: {
                label: {"Full name": "Full Name"},
                placeholder: {"Full name": "Full Name"}
            },
            firstStreet: {
                label: {"Address 1": "Address 1"},
                placeholder: {"Address 1": "Street + house/apt number"}
            },
            secondStreet: {
                label: {"Address 2": "Address 2"},
                placeholder: {"Address 2": "Street + house/apt number"}
            },
            city: {
                label: {City: "City"},
                placeholder: {City: "City"},
            },
            state: {
                label: {State: "State"},
                placeholder: {State: "State"}
            },
            postalCode: {
                label: {"Zip code": "Zip code"},
                placeholder: {"Zip code": "Zip code"}
            },
            identifier: {
                label: {CPF: "CPF"},
                placeholder: {CPF: "CPF"}
            }
        },
        IND: {
            state: {
                label: {"State/Territory": "State/Territory"},
                options: {
                    "Andaman and Nicobar Islands": "Andaman and Nicobar Islands",
                    "Arunachal Pradesh": "Arunachal Pradesh",
                    "Assam": "Assam",
                    "Bihar": "Bihar",
                    "Chandigarh": "Chandigarh",
                    "Chhattisgarh": "Chhattisgarh",
                    "Dadra and Nagar Haveli and Daman and Diu": "Dadra and Nagar Haveli and Daman and Diu",
                    "Delhi": "Delhi",
                    "Goa": "Goa",
                    "Gujarat": "Gujarat",
                    "Haryana": "Haryana",
                    "Himachal Pradesh": "Himachal Pradesh",
                    "Jammu and Kashmir": "Jammu and Kashmir",
                    "Jharkhand": "Jharkhand",
                    "Karnataka": "Karnataka",
                    "Kerala": "Kerala",
                    "Ladakh":"Ladakh",
                    "Lakshadweep": "Lakshadweep",
                    "Madhya Pradesh": "Madhya Pradesh",
                    "Maharashtra": "Maharashtra",
                    "Manipur": "Manipur",
                    "Meghalaya": "Meghalaya",
                    "Mizoram": "Mizoram",
                    "Nagaland": "Nagaland",
                    "Odisha": "Odisha",
                    "Puducherry": "Puducherry",
                    "Punjab": "Punjab",
                    "Rajasthan": "Rajasthan",
                    "Sikkim": "Sikkim",
                    "Tamil Nadu": "Tamil Nadu",
                    "Telangana":"Telangana",
                    "Tripura": "Tripura",
                    "Uttar Pradesh": "Uttar Pradesh",
                    "Uttarakhand": "Uttarakhand",
                    "West Bengal": "West Bengal",
                    "Andhra Pradesh": "Andhra Pradesh"
                }
            }
        }
    },
    errors: {
        required: "{field} is required.",
        mask: "{field} is invalid."
    },
    buttons: {
        submit: {
            text: "Save",
            saving: "Saving"
        },
        cancel: {
            text: "Cancel"
        }
    },
    disclaimer: "Adding this address will require email verification. After saving the address, please check your email and click the link within.",
    message: {
        added: "Address has been added",
        updated: "Address has been updated"
    }
}